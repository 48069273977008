var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',_vm._b({attrs:{"headers":_vm.showedHeaders,"items":_vm.showedPoints,"item-class":"text-body-1","caption":_vm.points.objectName || 'Без привязки',"use-caption-as-index":false,"hide-default-footer":true,"item-key":"номер"},on:{"click:row":function (ref) {
      var number = ref.number;

      _vm.emitUpdateSliderPreloaderEvt(true);
      _vm.$emit('update:slider', true);

      _vm.chooseAccountingPoint(
        _vm.points.pointList.find(function (point) { return point['номер'] === number; })
      ).finally(function () {
        _vm.emitUpdateSliderPreloaderEvt(false);
      });
    }},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('table-dropdown',{attrs:{"items":_vm.dropdownItems,"column":_vm.columnStates},on:{"update:column":function($event){_vm.columnStates=$event},"on-activator":function($event){return _vm.$emit('update:wall', true)}}})]},proxy:true}])},'x-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }